import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react'
import { compact, concat, find, get, map, take } from 'lodash'
import Div from 'component-library/components/Div'
import PropTypes from 'prop-types'

import Image from '@components/Image'
import LogoBrandSVG from '@components/LogoWithBrandName'
import { useClickOutside } from '@hooks'
import { ArrowNextSVG, ChevronSVG } from '@icons'
import {
  OnlyDesktop,
  OnlyDesktopAndTablet,
  OnlyMobile,
  OnlyTablet,
  OnlyTabletAndMobile,
} from '@styles/DisplayOnMedia'
import { ExternalLink, StyledLink } from '@styles/StyledLink'
import { Body2, Body2Bold, Body3, Body3Bold } from '@typography'
import { CONTENT_TYPES } from '@utils/ContentfulModels'
import {
  getBackTo,
  getMainMenu,
  getReadMore,
} from '@utils/getHardDataTranslated'
import { getLanguageTable } from '@utils/TranslationHelpers'

import BurgerCrossAnimatedButton from './components/BurgerCrossAnimatedButton'
import EarthSVG from './components/EarthSVG'
import MobileLangSelector from './components/MobileLangSelector'
import RightButton from './components/RightButton'
import TopMenuButton from './components/TopMenuButton'
import NavigationContext from './NavigationContext'
import {
  CenterPartNavigation,
  ColumnLargeDropdown,
  FirstButtonSubMenuWithParagraph,
  MainMenuButton,
  MainMenuButtonLink,
  MenuButtonTabletAndMobile,
  LogoBrand,
  NavigationContainer,
  RightPart,
  StyledMenu,
  StyledMenuPanel,
  StyledMenuPanelBackBtn,
  StyledMenuPanelTabletStep1,
  StyledMenuPanelTabletStep2,
  SubButtonArea,
  SubButtonLink,
  SubMenuCard,
  SubMenuCardText,
  SubPanelElement,
  TopMenu,
  TopMenuSubButton,
  TopNavigation,
} from './styles'
import ExternalLinkSVG from '@icons/ExternalLinkSVG'

const mailjetMainMenuType = [
  'BUTTON',
  'LARGE_DROPDOWN',
  'LARGE_DROPDOWN',
  'LARGE_DROPDOWN',
  'BUTTON',
  'LITTLE_DROPDOWN',
]
const mailgunMainMenuType = [
  'BUTTON',
  'LARGE_DROPDOWN',
  'LARGE_DROPDOWN',
  'LARGE_DROPDOWN',
  'LITTLE_DROPDOWN',
  'LARGE_DROPDOWN',
  'LITTLE_DROPDOWN',
]

const Navigation = ({
  currentSiteBaseUrl,
  hrefLangData,
  isNextServer,
  navigationData,
  scrollLimit,
}) => {
  const { brandLogoWithLink, mainMenu, node_locale, rightButton, topMenu } =
    navigationData
  const mainMenuType = currentSiteBaseUrl.includes('mailgun')
    ? mailgunMainMenuType
    : mailjetMainMenuType
  const languageTable = getLanguageTable()
  // Allow not to block the thread for updating menu with performance costing state update
  const [, startTransition] = useTransition()
  const [dropdownPanelIdSelected, setDropdownPanelIdSelected] = useState(null)
  // Use if they have more than two subpanel on tablet/mobile
  const [tabletStep, setTabletStep] = useState(1)
  // Indicate if the menu is reduce or not (pass to false on scrolldown)
  const [isLarge, setLarge] = useState(true)
  // Check last scroll
  const [lastScrollY, setLastScrollY] = useState(0)

  const mainPanelRef = useRef()
  const topMenuPanelRef = useRef()
  const [isPanelOpen, setPanelOpen] = useClickOutside(
    mainPanelRef,
    () => {
      setPanelOpen(false)
    },
    false,
    ['click'],
  )
  const [isTopMenuPanelOpen, setTopMenuPanelOpen] = useClickOutside(
    topMenuPanelRef,
    () => {
      setTopMenuPanelOpen(false)
    },
    false,
    ['click'],
  )

  const onClickBackButton = () => {
    setDropdownPanelIdSelected(null)
    setTabletStep(tabletStep - 1)
  }

  // Handle change of sizes (and thus open/close) when scrolling
  const onScroll = useCallback(() => {
    const currentScrollY = window.scrollY
    const scrollingUp = currentScrollY < lastScrollY

    // If scrolling up, we want the menu to open (large)
    // If scrolling down, we want the menu to close (not large)
    if (scrollingUp) {
      // Scrolling up: set menu to large (open)
      setLarge(true)
    } else {
      // Scrolling down: set menu to not large (closed)
      setLarge(scrollLimit > currentScrollY)
    }

    setLastScrollY(currentScrollY)
  }, [lastScrollY, scrollLimit])

  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [onScroll])

  // Language data and props
  const currentLangLabel = find(
    languageTable,
    ({ locale }) => locale === node_locale,
  ).name
  const getLocaleEquivalentPath = (language) => {
    const slug = hrefLangData.find(
      (data) =>
        data.hrefLang ===
        languageTable.find((obj) => obj.suffix === language).locale,
    ).href

    return slug.replace(currentSiteBaseUrl, '') || '/'
  }
  const languageButtonProps = {
    label: currentLangLabel,
    buttons: compact(
      map(languageTable, (language) => {
        const currentHreflangData = find(
          hrefLangData,
          ({ hrefLang }) => hrefLang === language.locale,
        )
        return (
          currentHreflangData && {
            id: language.name,
            label: language.name,
            link: getLocaleEquivalentPath(language.suffix),
          }
        )
      }),
    ),
    id: 'language-button',
  }

  // Dropdown data generations
  const getDropdownData = ({ buttons, id, index, isTopMenu, subpanelId }) => {
    const onClickButton = (e) => {
      startTransition(() => {
        e.stopPropagation()
        setPanelOpen(false)
        setTopMenuPanelOpen(false)
        setDropdownPanelIdSelected(null)
      })
    }

    let dropDownData
    if (isTopMenu) {
      dropDownData = (
        <>
          <OnlyMobile>{dropDownData}</OnlyMobile>

          {/* Dropdown buttons */}
          {map(buttons, (button) => (
            <TopMenuSubButton key={button?.id}>
              {button?.link?.[0] === '/' ? (
                <StyledLink onClick={onClickButton} to={button?.link}>
                  <Body2 as='span'>{button?.label}</Body2>
                </StyledLink>
              ) : (
                <ExternalLink onClick={onClickButton} href={button?.link}>
                  <Body2 as='span'>
                    {button?.label}
                    <ExternalLinkSVG />
                  </Body2>
                </ExternalLink>
              )}
            </TopMenuSubButton>
          ))}
        </>
      )
    } else {
      if (mainMenuType[index] === 'LITTLE_DROPDOWN') {
        dropDownData = (
          <>
            {map(buttons, (button) => (
              <li key={button?.id + '-navbar-key'}>
                <SubButtonLink
                  key={button?.id}
                  onClick={onClickButton}
                  to={button?.link}
                >
                  <Image
                    alt={button?.image?.alternative}
                    {...button?.image?.picture}
                  />
                  <Body2 as='span'>{button?.label}</Body2>
                </SubButtonLink>
              </li>
            ))}
          </>
        )
      } else if (mainMenuType[index] === 'LARGE_DROPDOWN') {
        dropDownData = (
          <>
            {/* We want 4 elements max */}
            {map(take(buttons, 4), (button) => {
              // First button with image and label, and his sub buttons
              if (button?.__typename === CONTENT_TYPES.DROPDOWN) {
                return (
                  <ColumnLargeDropdown
                    key={button?.id + '-ColumnLargeDropdown-' + index}
                  >
                    <ul
                      key={
                        button?.id + '-FirstButtonSubMenuWithParagraph-' + index
                      }
                    >
                      <FirstButtonSubMenuWithParagraph
                        hasLink={!!button?.buttons?.[0]?.link}
                        key={button?.id}
                        onClick={onClickButton}
                        to={button?.buttons?.[0]?.link}
                      >
                        <Image
                          alt={button?.buttons?.[0].image?.alternative}
                          {...button?.buttons?.[0].image?.picture}
                        />
                        <Div di='f' fd='c'>
                          <Body2Bold as='span'>{button?.label}</Body2Bold>
                          {button?.description && (
                            <Body3 as='span'>{button?.description}</Body3>
                          )}
                        </Div>
                      </FirstButtonSubMenuWithParagraph>
                      <hr />
                    </ul>
                    <SubButtonArea>
                      {map(
                        button.buttons,
                        (subButton, index) =>
                          index !== 0 &&
                          subButton?.link && (
                            <li key={'SubButtonArea-' + index}>
                              <SubButtonLink
                                key={subButton?.id}
                                onClick={onClickButton}
                                to={subButton.link}
                              >
                                <Image
                                  alt={subButton?.image?.alternative}
                                  {...subButton?.image?.picture}
                                />
                                <Body2 as='span'>{subButton?.label}</Body2>
                              </SubButtonLink>
                            </li>
                          ),
                      )}
                    </SubButtonArea>
                  </ColumnLargeDropdown>
                )
                // Big image button
              } else if (
                (button?.__typename === CONTENT_TYPES.BUTTON ||
                  button?.__typename === CONTENT_TYPES.CARD) &&
                (button?.link || button?.url)
              ) {
                return (
                  <SubMenuCard key={button?.id + '-SubMenuCard-' + index}>
                    <StyledLink
                      onClick={onClickButton}
                      to={button?.link || button?.url}
                    >
                      <Image
                        alt={button?.image?.alternative}
                        {...button?.image?.picture}
                      />
                      <SubMenuCardText>
                        <Body3Bold as='span' color='neutral100'>
                          {button?.label || button?.title}
                        </Body3Bold>
                        <div>
                          <Body3Bold as='span' color='secondary60'>
                            {getReadMore()[node_locale]}
                          </Body3Bold>
                          <ArrowNextSVG />
                        </div>
                      </SubMenuCardText>
                    </StyledLink>
                  </SubMenuCard>
                )
              }
            })}
          </>
        )
      }
    }

    return (
      <SubPanelElement
        className={'subpanel-' + subpanelId + index}
        isOpen={isTopMenu ? isTopMenuPanelOpen : isPanelOpen}
        key={'subpanel' + id}
        onClick={(e) => e.stopPropagation()}
        type={isTopMenu ? 'TOP_MENU' : mainMenuType[index]}
      >
        {dropDownData}
      </SubPanelElement>
    )
  }

  // Main buttons generation
  const getNavBarButtonAndDropDownData = (
    { label, buttons, id, link },
    index,
    subpanelId,
    isTopMenu = false,
    isLanguageButton = false,
  ) => {
    // Avoid display button with no label, can be used when button should only be displayed on some lang
    if (!label) {
      return <></>
    }

    const navbarButtonAndChild = {
      subPanelAndThirdPanel: [],
      navbarButton: [],
    }
    let subPanel = <React.Fragment key={id + 'subpanel'}></React.Fragment>
    // Allow running the content building skipping startTransition on server side
    const renderContentWithTransition = typeof window === 'undefined' ? (func) => func() : startTransition
    renderContentWithTransition(() => {
      // Top Menu
      if (isTopMenu) {
        const isDropdown = buttons && buttons.length > 0
        const onClickTopMenuDropdown = (newDropdownPanel) => {
          startTransition(() => {
            if (!isDropdown) {
              setPanelOpen(false)
              setTopMenuPanelOpen(false)
            } else {
              if (dropdownPanelIdSelected === newDropdownPanel) {
                setPanelOpen(!isPanelOpen)
                setTopMenuPanelOpen(!isTopMenuPanelOpen)
              } else {
                setPanelOpen(true)
                setTopMenuPanelOpen(true)
              }
              setTabletStep(2)
              setDropdownPanelIdSelected(subpanelId + index)
            }
          })
        }
        if (isDropdown) {
          navbarButtonAndChild.subPanelAndThirdPanel = getDropdownData({
            buttons,
            id,
            index,
            isTopMenu,
            subpanelId,
          })
          subPanel = navbarButtonAndChild.subPanelAndThirdPanel
        }

        const navbarCommonProps = {
          'aria-label': label,
          className: 'menubutton-' + subpanelId + index,
          isLanguageButton,
          label,
          rotation:
            isTopMenuPanelOpen && dropdownPanelIdSelected === subpanelId + index
              ? '-90'
              : '90',
          to: link,
        }
        const key = 'menubutton' + id

        navbarButtonAndChild.navbarButton = (
          <li key={id + 'top'}>
            <TopMenuButton
              onClick={() => onClickTopMenuDropdown(subpanelId + index)}
              {...navbarCommonProps}
              {...{ isDropdown }}
              key={key}
            >
              {subPanel}
            </TopMenuButton>
          </li>
        )

        // Center Menu
      } else {
        // Simple buttons
        if (mainMenuType[index] === 'BUTTON') {
          navbarButtonAndChild.navbarButton = (
            <li key={id}>
              <MainMenuButtonLink to={link}>
                <OnlyDesktop>
                  <Body3Bold as='span'>{label}</Body3Bold>
                </OnlyDesktop>
                <OnlyTabletAndMobile>
                  <Body2 as='span'>{label}</Body2>
                </OnlyTabletAndMobile>
              </MainMenuButtonLink>
            </li>
          )
          // Dropdowns
        } else {
          const onClickDropdown = (newDropdownPanel) => {
            if (dropdownPanelIdSelected === newDropdownPanel) {
              setPanelOpen(!isPanelOpen)
            } else {
              setPanelOpen(true)
            }
            setTopMenuPanelOpen(false)
            setTabletStep(2)
            setDropdownPanelIdSelected(newDropdownPanel)
          }

          navbarButtonAndChild.subPanelAndThirdPanel = getDropdownData({
            buttons,
            id,
            index,
            isTopMenu,
            subpanelId,
          })
          subPanel = navbarButtonAndChild.subPanelAndThirdPanel

          const navbarCommonProps = {
            'aria-label': label,
            className: 'menubutton-' + subpanelId + index,
            isDropdown: true,

            label,
          }

          const key = 'menubutton' + id

          navbarButtonAndChild.navbarButton = (
            <li key={id + 'child'}>
              <OnlyDesktop key={key + 'Desktop'}>
                <MainMenuButton
                  onClick={() => onClickDropdown(subpanelId + index)}
                  {...navbarCommonProps}
                >
                  <Body3Bold as='span'>{label}</Body3Bold>
                  <ChevronSVG
                    rotation={
                      isPanelOpen &&
                      dropdownPanelIdSelected === subpanelId + index
                        ? '-90'
                        : '90'
                    }
                  />
                </MainMenuButton>
                {subPanel}
              </OnlyDesktop>
              <OnlyTabletAndMobile key={key + 'Tablet'}>
                <MainMenuButton
                  onClick={() => onClickDropdown(subpanelId + index)}
                  {...navbarCommonProps}
                >
                  <Div di='f' jc='sb' ai='ce' w='100%'>
                    <Body2 as='span'>{label}</Body2>
                    <ChevronSVG />
                  </Div>
                  {subPanel}
                </MainMenuButton>
              </OnlyTabletAndMobile>
            </li>
          )
        }
      }
    })

    return navbarButtonAndChild
  }
  // Todo: if useTransition is not enough, maybe generate one content for each device type and store it right away
  const getCenterContent = () => {
    const newSubPanelArray = []

    return {
      centerSideContent: map(mainMenu, (el, index) => {
        const navbarData = getNavBarButtonAndDropDownData(
          el,
          index,
          'mainMenu-',
        )
        newSubPanelArray.push(navbarData.subPanelAndThirdPanel)
        return navbarData.navbarButton
      }),
      subPanel: newSubPanelArray,
    }
  }

  const getTopMenuContent = () => {
    const newSubPanelArray = []
    const newTopMenu = map(topMenu, (el, index) => {
      const topMenuData = getNavBarButtonAndDropDownData(
        el,
        index,
        'topMenu-',
        true,
      )
      newSubPanelArray.push(topMenuData.subPanelAndThirdPanel)
      return topMenuData.navbarButton
    })
    newTopMenu.push(
      <React.Fragment
        key={'topMenu-' + languageButtonProps?.id + 'desktop-tablet'}
      >
        <OnlyDesktopAndTablet>
          {
            getNavBarButtonAndDropDownData(
              languageButtonProps,
              newTopMenu.length,
              'topMenu-',
              true,
              true,
            ).navbarButton
          }
        </OnlyDesktopAndTablet>
      </React.Fragment>,
    )

    return {
      subPanel: newSubPanelArray,
      topMenuContent: newTopMenu,
    }
  }

  const centerMenuData = useMemo(() => {
    return getCenterContent()
  }, [dropdownPanelIdSelected, isPanelOpen, node_locale])
  const { centerSideContent } = centerMenuData
  const topSubMenuData = useMemo(
    () => getTopMenuContent(),
    [dropdownPanelIdSelected, hrefLangData, isTopMenuPanelOpen, node_locale],
  )
  const { topMenuContent } = topSubMenuData
  const subPanelArray = concat(centerMenuData.subPanel, topSubMenuData.subPanel)
  const rightMenuContent = (
    <li key={rightButton?.id + '-right'}>
      <RightButton small to={rightButton?.link} noShadow>
        {rightButton?.label}
      </RightButton>
    </li>
  )

  useEffect(() => {
    // Init scroll side effect on page render
    onScroll()
  }, [])

  const updateContent = () => {
    setPanelOpen(false)
    setTopMenuPanelOpen(false)
  }

  useEffect(updateContent, [hrefLangData, node_locale])

  // Specify if on tablet mode in order to handle top menu or not
  const onChangeBurgerMenuButton = (event, isTablet = false) => {
    startTransition(() => {
      event.stopPropagation()
      if (isTablet) setTopMenuPanelOpen(false)
      setPanelOpen(!isPanelOpen)
      setTabletStep(1)
    })
  }

  return (
    <NavigationContext.Provider value={{ isNavigationChild: true }}>
      <TopMenu
        isLarge={isLarge}
        isOpen={isTopMenuPanelOpen}
        selectedPanel={dropdownPanelIdSelected}
        ref={topMenuPanelRef}
      >
        <nav>
          <TopNavigation>{topMenuContent}</TopNavigation>
        </nav>
      </TopMenu>
      <StyledMenu
        isLarge={isLarge}
        isOpen={isPanelOpen}
        isTopMenuPanelOpen={isTopMenuPanelOpen}
        ref={mainPanelRef}
        selectedPanel={dropdownPanelIdSelected}
      >
        <NavigationContainer>
          <LogoBrand>
            <StyledLink to={get(brandLogoWithLink, 'link', '/')}>
              <LogoBrandSVG />
            </StyledLink>
          </LogoBrand>
          <Div di='f'>
            <OnlyDesktop>
              <CenterPartNavigation>{centerSideContent}</CenterPartNavigation>
            </OnlyDesktop>
            <OnlyTablet>
              <MenuButtonTabletAndMobile
                aria-label={
                  isPanelOpen && !isTopMenuPanelOpen ? 'Close' : 'Open'
                }
              >
                <BurgerCrossAnimatedButton
                  isOpen={isPanelOpen && !isTopMenuPanelOpen}
                  onChange={(e) => onChangeBurgerMenuButton(e, true)}
                />
              </MenuButtonTabletAndMobile>
            </OnlyTablet>
            <OnlyMobile>
              <MenuButtonTabletAndMobile
                aria-label={isPanelOpen ? 'Close' : 'Open'}
              >
                <BurgerCrossAnimatedButton
                  isOpen={isPanelOpen}
                  onChange={onChangeBurgerMenuButton}
                />
              </MenuButtonTabletAndMobile>
            </OnlyMobile>
          </Div>

          <RightPart>
            <OnlyDesktopAndTablet>{rightMenuContent}</OnlyDesktopAndTablet>
            <OnlyMobile>
              <MobileLangSelector
                mode='icon'
                icon={EarthSVG}
                id='language_menu'
              >
                <ul>
                  {map(languageButtonProps.buttons, ({ id, label, link }) => (
                    <TopMenuSubButton key={id}>
                      <StyledLink to={link}>
                        <Body2 as='span'>{label}</Body2>
                      </StyledLink>
                    </TopMenuSubButton>
                  ))}
                </ul>
              </MobileLangSelector>
            </OnlyMobile>
          </RightPart>
        </NavigationContainer>
        <OnlyTabletAndMobile>
          <StyledMenuPanel isLarge={isLarge} tabletStep={tabletStep}>
            <StyledMenuPanelBackBtn noShadow onClick={onClickBackButton}>
              <ChevronSVG color='neutral60' rotation={'-180'} />
              <Body2>
                {getBackTo()[node_locale] + ' ' + getMainMenu()[node_locale]}
              </Body2>
            </StyledMenuPanelBackBtn>
            <StyledMenuPanelTabletStep1
              isOpen={isPanelOpen && tabletStep === 1}
            >
              {centerSideContent}
              <OnlyMobile>
                <Div di='f' fd='c'>
                  {topMenuContent}
                </Div>
                {rightMenuContent}
              </OnlyMobile>
            </StyledMenuPanelTabletStep1>
            <StyledMenuPanelTabletStep2
              isOpen={isPanelOpen && tabletStep === 2}
              selectedPanel={dropdownPanelIdSelected}
            >
              {subPanelArray}
            </StyledMenuPanelTabletStep2>
          </StyledMenuPanel>
        </OnlyTabletAndMobile>
      </StyledMenu>
    </NavigationContext.Provider>
  )
}

Navigation.propTypes = {
  currentSiteBaseUrl: PropTypes.string,
  hrefLangData: PropTypes.array,
  location: PropTypes.object,
  navigationData: PropTypes.object,
  scrollLimit: PropTypes.number,
}

export default Navigation
